


import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/resources.css"


function Resources () {
	
	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/resources/*.png" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid_noBase64
	                }
	              }
	            }
	          }
	        }
			}
	`


	const images = useStaticQuery(query).allFile

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content resources">

				<h2>Congregational Singing Of Saint John Orthodox Cathedral</h2>
				
								<div className="recording-iframe-wrapper first-recording">
<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1691806902&color=%23ff5500&auto_play=true&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=true"></iframe>
</div>



			</main>
			<Footer />		
		</div>
		)
}

export default Resources 
